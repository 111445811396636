/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1s9v8ag pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box fs--36" content={"<span style=\"font-weight: bold; font-style: italic;\">V naší nabídce jsou také&nbsp;</span>"}>
              </Text>

              <Text className="text-box text-box--center fs--43" style={{"maxWidth":488}} content={"<span style=\"font-style: italic; color: var(--color-supplementary); font-weight: bold;\">Kvalitní České dveře kterým můžete věřit&nbsp; SAPELI&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"041ro9jtaxyl"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/1be738a57e304d0f82e4f455d42af87f_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/1be738a57e304d0f82e4f455d42af87f_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Elegant laminat"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/f81c1cac210642bab56b26f5f139d330_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/f81c1cac210642bab56b26f5f139d330_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Elegant inspirace2"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/12c715a638304493bbf1a2028eb8aaa4_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/12c715a638304493bbf1a2028eb8aaa4_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Glatt harmonie"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"jfygx525p9"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/f01b9412e44a43a6b5a1766b3a3ac9e2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/f01b9412e44a43a6b5a1766b3a3ac9e2_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Industriální stěna velká"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/b22c5bc98f834b86ba525994793cac53_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/b22c5bc98f834b86ba525994793cac53_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Kubika 1"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/1f82d2e7703a412bbf0f1abaa1fe1412_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/1f82d2e7703a412bbf0f1abaa1fe1412_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Kubika industrial"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"trzfmidy87t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"a mnoho dalších variant a možností"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"1kc2cajc4z"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4 fs--30" content={"Kontaktujte nás"} use={"page"} href={"/kontakt-1"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}